
import React from 'react'
// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import Membership from './src/components/Membership'
import config from './gatsby-config'
import { WagmiProvider } from 'wagmi'
import { WagmiConfig } from './src/config/wagmi-config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()


export const wrapRootElement = ({ element }) => {
  return (
    <WagmiProvider config={WagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Membership locks={config.siteMetadata.locks}>{element}</Membership>
      </QueryClientProvider>
    </WagmiProvider>
  );
}