import React from 'react'
import UnlockContext from "../contexts/UnlockContext"
import { ethers } from "ethers"
import { useReadContract } from 'wagmi'
import gatsbyConfig from '../../gatsby-config'


export const Membership = ({ children, locks }) => {

  const [address, setAddress] = React.useState("")

  const becomeMember = () => {
    const paywallConfigLocks = {}
    locks.forEach(l => {
      paywallConfigLocks[l.address] = {
        network: l.network,
        recurringPayments: 36, // 3 years!
        skipRecipient: true,
      }
    })
    const paywallConfig = {
      locks: paywallConfigLocks,
      pessimistic: true,
    }
    window.location = `https://app.unlock-protocol.com/checkout?paywallConfig=${encodeURIComponent(JSON.stringify(paywallConfig))}&redirectUri=${encodeURIComponent(window.location.href)}`
  }

  const signIn = () => {
    window.location = `https://app.unlock-protocol.com/checkout?client_id=${window.location.host}&redirect_uri=${window.location.href}`
  }

  const signOut = () => {
    setAddress("")
  }

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.code) {
      const code = JSON.parse(atob(params.code))
      setAddress(ethers.utils.verifyMessage(code.d, code.s))
    }
  }, [])


  const { data: hasValidKey, error, isSuccess } = useReadContract({
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_keyOwner",
          "type": "address"
        }
      ],
      "name": "getHasValidKey",
      "outputs": [
        {
          "internalType": "bool",
          "name": "isValid",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    address: gatsbyConfig.siteMetadata.locks[0].address,
    functionName: 'getHasValidKey',
    args: [address],
  })

  let isMember = 'loading'
  if (hasValidKey && isSuccess) {
    isMember = 'yes'
  } else if (hasValidKey === false && isSuccess) {
    isMember = 'no'
  }


  return <UnlockContext.Provider value={{ isMember, becomeMember, address, signIn, signOut }}>
    {children}
  </UnlockContext.Provider>

}

export default Membership